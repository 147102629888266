import { USER_LOGIN, UPDATE_PROFILE, USER_LOGOUT, REFRESH_TOKEN } from './actionTypes';
import { FORM_SUBMIT_FAIL } from '../errors/actionTypes';

import axios from '../axios';

export const login = formData => dispatch => {
  axios.post('/users/login', formData, { withCredentials: true })
    .then(res => {
      const {
        status,
        message,
        id,
        firstname,
        lastname,
        email,
        role,
        profile,
        createdAt,
        updatedAt,
        token
      } = res.data;

      if (status !== true) {
        return dispatch({
          type: FORM_SUBMIT_FAIL,
          payload: {
            status,
            message,
          },
        });
      }

      const payload = {
        id,
        lastname,
        firstname,
        email,
        profile,
        role,
        createdAt,
        updatedAt
      }

      //shrani v local storage, nato se ob vsakem requestu v headerju poslje
      //nastavljeno je v App.js
      window.localStorage.setItem('token', token);

      return dispatch({
        type: USER_LOGIN,
        payload,
      });

    })
    .catch(err => console.log(err));
}

export const updateProfile = (formData) => dispatch => {
  axios.put('/users/update', formData)
    .then(res => {
      const {
        id,
        firstname,
        lastname,
        email,
        profile,
        role,
        createdAt,
        updatedAt,
        token
      } = res.data;

      const payload = {
        id,
        firstname,
        lastname,
        email,
        profile,
        role,
        createdAt,
        updatedAt,
        token
      }

      return dispatch({
        type: UPDATE_PROFILE,
        payload
      });

    })
    .catch(err => console.log(err));

}

export const logout = () => dispatch => {
  window.localStorage.removeItem('state');

  return dispatch({
    type: USER_LOGOUT,
    payload: {}
  });
}

//todo jozeb
export const refreshToken = () => dispatch => {

  axios.get('/users/refresh', { withCredentials: true })
    .then(res => {
      //debugger
      const {
        accessToken,
      } = res.data;

      //novi access token
      axios.defaults.headers.common['Authorization'] = accessToken;

      return dispatch({
        type: REFRESH_TOKEN,
        //payload
      });

    })
    .catch(err => console.log(err));
}
