
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT, DemoData, CellUnits} from 'react-big-scheduler';
import { withStyles } from '@material-ui/core/styles';
import {DragDropContext} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import 'moment/locale/sl'
import MomentUtils from "@date-io/moment";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
    DateTimePicker
  } from '@material-ui/pickers';
import axios from '../../services/axios';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DatepickerMessages from '../datetimepicker.localization';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import 'react-big-scheduler/lib/css/style.css'

const styles = theme => ({
    root: {
      //width: '80%',
      //marginTop: theme.spacing(13),
      //width: '100%',
      //marginTop: theme.spacing(3),
      //overflowX: 'auto',
    },
    paper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
        //textAlign: 'center',
        //maxWidth: 800,
    }
});

// const events = [
//     {
//          id: 1,
//          start: '2019-11-13 09:30:00',
//          end: '2019-11-13 11:30:00',
//          resourceId: 'r1',
//          title: 'Programiranje JS',
//          bgColor: '#D9D9D9'
//      },
//      {
//          id: 2,
//          start: '2017-12-18 12:30:00',
//          end: '2017-12-26 23:30:00',
//          resourceId: 'r2',
//          title: 'I am not resizable',
//          resizable: false
//      },
//      {
//          id: 3,
//          start: '2017-12-19 12:30:00',
//          end: '2017-12-20 23:30:00',
//          resourceId: 'r3',
//          title: 'I am not movable',
//          movable: false
//      },
//      {
//          id: 4,
//          start: '2017-12-19 14:30:00',
//          end: '2017-12-20 23:30:00',
//          resourceId: 'r1',
//          title: 'I am not start-resizable',
//          startResizable: false
//      },
//      {
//          id: 5,
//          start: '2017-12-19 15:30:00',
//          end: '2017-12-20 23:30:00',
//          resourceId: 'r2',
//          title: 'R2 has recurring tasks every week on Tuesday, Friday',
//          rrule: 'FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR',
//          bgColor: '#f759ab'
//      }
//  ];

const resources = [
    {
        id: 'r0',
        name: 'Kangoo moder',
        //groupOnly: true
    },
    {
        id: 'r1',
        name: 'Dacia'
    },
    {
        id: 'r2',
        name: 'Land Rover'
    },
    {
        id: 'r3',
        name: 'Kangoo beli'
    }
];

class Rezervacija extends Component {

    constructor(props){
        super(props);

        //moment.locale('sl');      ni potrebno, ker ga nastavimo z importom
        let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Day, false, false, {
            //displayWeekend: false,
            //weekCellWidth: '16%',
            schedulerWidth: '85%',
            //besidesWidth: 240,
            // dayMaxEvents: 1,
            // weekMaxEvents: 5,
            // monthMaxEvents: 4,
            // yearMaxEvents: 4,
            nonAgendaDayCellHeaderFormat: 'D.M|HH:00',
            //nonAgendaDayCellHeaderFormat: 'D.M|HH:mm',
            customCellWidth: 30,
            resourceName: 'Vozilo',
            //dayStartFrom: 6,    //delovni čas od
            //dayStopTo: 17,      //delovni čas do
            
            // taskName: 'Rezervacija',
            // agendaViewHeader: 'Agenda view header',
            // addMorePopoverHeaderFormat: 'YYYY年M月D日 dddd',
            // eventItemPopoverDateFormat: 'M月D日',
            // nonAgendaDayCellHeaderFormat: 'HH:mm',
            // nonAgendaOtherCellHeaderFormat: 'ddd|M/D',
            views: [
                //{viewName: 'Delovni teden', viewType: ViewTypes.Custom, showAgenda: false, isEventPerspective: false},
                {viewName: 'Dan', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
                {viewName: 'Teden', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
                {viewName: 'Mesec', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false},
                //  {viewName: 'Četrtletje', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false},
                //  {viewName: 'Leto', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false},   
            ],
        }, {
            //getCustomDateFunc: this.getCustomDate,
            //getDateLabelFunc: this.getDateLabel,
            isNonWorkingTimeFunc: this.isNonWorkingTime
        }, moment);
        schedulerData.setResources(resources);
        
        //schedulerData.setResources(DemoData.resources);
        //schedulerData.setEvents(DemoData.events);
        this.state = {
            viewModel: schedulerData,
            headerItem: undefined,
            user: props.user,
            beforeSubmitError: false,
            vozilo : undefined,
            vrsta: 0,
            datumOd: null,
            datumDo: null,
            snackbarOpen: false,
            snackbarMessage: '',
            events: undefined,
            dateOdToSmall: false,
            dateDoToSmall: false,
            dateDoBeforeDateOd : false,
            dialogDeleteOpen: false,
            toDeleteId : ''
        }

    }

    componentDidMount() {
        this.refreshEvents();    
    }

    refreshEvents = () => {

        // const { user } = this.props;
        // const config = {
        //     headers: {
        //         'Authorization': user.token,
        //     }
        // };

        axios.get('/rezervacija'/*, config*/)
            .then(res => {
                this.setState({events: res.data.data});
                
                const schedulerData = this.state.viewModel;
                schedulerData.setEvents(this.state.events);

                this.setState({
                     viewModel: schedulerData
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, event) => {
        this.setState({
            dialogDeleteOpen: true,
            toDeleteId: event.id
          });

        //alert('You just clicked an event: {id: ${event.id}, title: ${event.title}}');
    };

    ops1 = (schedulerData, event) => {
        //alert('You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}');
    };

    ops2 = (schedulerData, event) => {
        //alert('You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}');
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let startDate = moment(start);

        if(startDate.isBefore(moment())){
            return false;
        }

        const {user} = this.state;
        const data = {
            besedilo: 'Rezerviral: ' + user.firstname + " " + user.lastname,
            obdobjeod: start,
            obdobjedo: end,
            vozilo: slotId,
            vrsta: 0,
            idustvaril: user.id
        }

        this.addEvent(data);
    }

    updateEventStart = (schedulerData, event, newStart) => {
        // if(confirm('Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}')) {
        //     schedulerData.updateEventStart(event, newStart);
        // }
        // this.setState({
        //     viewModel: schedulerData
        // })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        // if(confirm('Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}')) {
        //     schedulerData.updateEventEnd(event, newEnd);
        // }
        // this.setState({
        //     viewModel: schedulerData
        // })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        // if(confirm('Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}')) {
        //     schedulerData.moveEvent(event, slotId, slotName, start, end);
        //     this.setState({
        //         viewModel: schedulerData
        //     })
        // }
    }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }

    getCustomDate = (schedulerData, num, date = undefined) => {
        const {viewType} = schedulerData;
        let selectDate = schedulerData.startDate;
        if(date != undefined)
            selectDate = date;   

        let startDate = num === 0 ? selectDate : 
            schedulerData.localeMoment(selectDate).add(5*num, 'days').format(DATE_FORMAT),
            endDate = schedulerData.localeMoment(startDate).add(4, 'days').format(DATE_FORMAT),
            cellUnit = CellUnits.Hour;
        if(viewType === ViewTypes.Custom1) {
            let monday = schedulerData.localeMoment(selectDate).startOf('week').format(DATE_FORMAT);
            startDate = num === 0 ? monday : schedulerData.localeMoment(monday).add(2*num, 'weeks').format(DATE_FORMAT);
            endDate = schedulerData.localeMoment(startDate).add(1, 'weeks').endOf('week').format(DATE_FORMAT);
            cellUnit = CellUnits.Day;
        } 
        // else if(viewType === ViewTypes.Custom2) {
        //     let firstDayOfMonth = schedulerData.localeMoment(selectDate).startOf('month').format(DATE_FORMAT);
        //     startDate = num === 0 ? firstDayOfMonth : schedulerData.localeMoment(firstDayOfMonth).add(2*num, 'months').format(DATE_FORMAT);
        //     endDate = schedulerData.localeMoment(startDate).add(1, 'months').endOf('month').format(DATE_FORMAT);
        //     cellUnit = CellUnits.Day;
        // }
            
        return {
            startDate,
            endDate,
            cellUnit
        };
    }

    // getDateLabel = (schedulerData, viewType, startDate, endDate) => {
    //     let start = schedulerData.localeMoment(startDate);
    //     let end = schedulerData.localeMoment(endDate);
    //     let dateLabel = start.format('YYYY年M月D日');

    //     if(viewType === ViewTypes.Week) {
    //         dateLabel = `${start.format('YYYY年M月D日')}-${end.format('D日')}`;
    //         if(start.month() !== end.month())
    //             dateLabel = `${start.format('YYYY年M月D日')}-${end.format('M月D日')}`;
    //         if(start.year() !== end.year())
    //             dateLabel = `${start.format('YYYY年M月D日')}-${end.format('YYYY年M月D日')}`;
    //     }
    //     else if(viewType === ViewTypes.Month){
    //         dateLabel = start.format('YYYY年M月');
    //     }
    //     else if(viewType === ViewTypes.Quarter){
    //         dateLabel = `${start.format('YYYY年M月D日')}-${end.format('M月D日')}`;
    //     }
    //     else if(viewType === ViewTypes.Year) {
    //         dateLabel = start.format('YYYY年');
    //     }

    //     return dateLabel;
    // }

    isNonWorkingTime = (schedulerData, time) => {
        let current = moment(time);
        if(current.isBefore(new moment()))
            return true;
        else return false;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    dateOdChange = value => {
        let startDate = moment(value);

        if(startDate.isBefore(moment())){
            this.setState({dateOdToSmall: true});
            return false;
        }
        else{
            this.setState({dateOdToSmall: false});
        }

        this.setState({ datumOd: value });
    }

    dateDoChange = value => {
        let startDate = moment(value);

        if(startDate.isBefore(moment())){
            this.setState({dateDoToSmall: true});
            return false;
        }
        else{
            this.setState({dateDoToSmall: false});
        }

        this.setState({ datumDo: value });
    }

    addEvent = (data) => {
        axios.post('/rezervacija/add', data)
                .then(res => {
                    const { status, message } = res.data;

                    if (status !== true) {
                        console.log("Napaka pri rezervaciji vozila")
                    } else {
                        
                        this.setState({
                            vozilo : undefined,
                            vrsta: 0,
                            datumOd: null,
                            datumDo: null,
                            snackbarOpen: true,
                            snackbarMessage: 'Rezervacija uspešna'
                        })

                        this.refreshEvents();     
                    }
                })
                .catch(err => console.log(err));
    };

    handleSubmit = event => {
        event.preventDefault();
        const { user } = this.props;

        let dateOd = moment(this.state.datumOd);
        let dateDo = moment(this.state.datumDo);

        if(dateOd.isValid() && dateDo.isValid() && dateDo.isBefore(dateOd)){
            this.setState({dateDoBeforeDateOd: true});
            return false;
        }
        else
            this.setState({dateDoBeforeDateOd: false});

        const data = {
            besedilo: 'Rezerviral: ' + user.firstname + " " + user.lastname,
            obdobjeod: this.state.datumOd,
            obdobjedo: this.state.datumDo,
            vozilo: this.state.vozilo,
            vrsta: this.state.vrsta,
            idustvaril: user.id
        }

        const blankInputs = Object.keys(data).filter(key => data[key] === '' || data[key] === undefined || data[key] === null);

        if (blankInputs.length > 0)
            this.setState({beforeSubmitError: true});
        else {
            this.setState({beforeSubmitError: false});

            this.addEvent(data);            
        }
    };

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ snackbarOpen: false });
    };

    handleDialogCancel = () => {
        this.setState({dialogDeleteOpen : false});  
    };
    
    handleDialogOK = () => {
        this.setState({dialogDeleteOpen : false});  

        const { user } = this.props;

        if(this.state.toDeleteId !== ''){
            axios.delete('/rezervacija/delete/' + this.state.toDeleteId)
            .then(res => {
                if(res.data && res.data.status === "REZERVACIJA_DELETED"){
                    this.setState({
                        events: this.state.events.filter(el => el.id !== this.state.toDeleteId),
                        toDeleteId: ''
                    })
                
                    const schedulerData = this.state.viewModel;
                    schedulerData.setEvents(this.state.events);

                    this.setState({
                        viewModel: schedulerData
                    });  
                }else if(res.data && res.data.status === "UNAUTHORIZED_DELETE"){
                    this.setState({
                        snackbarOpen: true,
                        snackbarMessage: 'Izbrišete lahko samo svoje rezervacije!'
                    })
                }
            })
            .catch(err => console.log(err));
        }
    }

    render() {
        const { classes } = this.props;
        const { viewModel} = this.state;
    
        return (
            <React.Fragment  >
            <MuiPickersUtilsProvider utils={MomentUtils} locale='sl'> 

            <Typography variant="h6" color="textSecondary" noWrap>Rezervacija službenih vozil</Typography>

            <Paper className={classes.paper}>
                <form onSubmit={this.handleSubmit}>
                    <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item lg={2}>
                            <FormControl fullWidth error={this.state.beforeSubmitError && this.state.vrsta === '' ? true : false}>
                            <InputLabel id="input-vrsta">Vrsta</InputLabel>
                            <Select
                                id="input-vrsta"
                                name="vrsta"
                                value={this.state.vrsta}
                                onChange={this.onChange}
                                >
                                    <MenuItem value='0'>Rezervacija</MenuItem>
                                    <MenuItem value='1'>Predlog rezervacije</MenuItem>
                            </Select>
                            {(this.state.beforeSubmitError && this.state.vrsta === '') &&
                                    <FormHelperText>Izberite vrsto rezervacije</FormHelperText>
                            }
                            </FormControl>
                        </Grid>

                        <Grid item lg={1}>
                            <FormControl fullWidth error={this.state.beforeSubmitError && this.state.vozilo === undefined ? true : false}>
                            <InputLabel id="input-vozilo">Vozilo</InputLabel>
                            <Select
                                id="input-vozilo"
                                name="vozilo"
                                value={this.state.vozilo}
                                onChange={this.onChange}
                                >
                                    <MenuItem value='r0'>Kangoo moder</MenuItem>
                                    <MenuItem value='r1'>Dacia</MenuItem>
                                    <MenuItem value='r2'>Land Rover</MenuItem>
                                    <MenuItem value='r3'>Kangoo beli</MenuItem>
                            </Select>
                            {(this.state.beforeSubmitError && this.state.vozilo === undefined) &&
                                    <FormHelperText>Izberite vozilo</FormHelperText>
                            }
                            </FormControl>
                        </Grid>

                        <Grid item lg={2}>
                            <FormControl fullWidth error={this.state.beforeSubmitError && this.state.datumOd === null ? true : false}>
                                <KeyboardDateTimePicker
                                    {...DatepickerMessages}
                                    value={this.state.datumOd}
                                    ampm={false}
                                    name='datumOd'
                                    disablePast
                                    onChange={this.dateOdChange}
                                    label="od"
                                    showTodayButton
                                    format="DD.MM.YYYY HH:mm"
                                    clearable
                                />
                                {(this.state.beforeSubmitError && this.state.datumOd === null) &&
                                    <FormHelperText>Izberite datum</FormHelperText>
                                }
                                {(this.state.dateOdToSmall) &&
                                    <FormHelperText error={true}>Datum rezervacije mora biti večji od trenutnega datuma</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item lg={2}>
                            <FormControl fullWidth error={this.state.beforeSubmitError && this.state.datumDo === null ? true : false}> 
                                <KeyboardDateTimePicker
                                    {...DatepickerMessages}
                                    value={this.state.datumDo}
                                    ampm={false}
                                    name='datumDo'
                                    disablePast
                                    onChange={this.dateDoChange}
                                    label="do"
                                    showTodayButton
                                    format="DD.MM.YYYY HH:mm"
                                    clearable
                                />
                                {(this.state.beforeSubmitError && this.state.datumDo === null) &&
                                    <FormHelperText>Izberite datum</FormHelperText>
                                }
                                {(this.state.dateDoToSmall) &&
                                    <FormHelperText error={true}>Datum rezervacije mora biti večji od trenutnega datuma</FormHelperText>
                                }
                                {(this.state.dateDoBeforeDateOd) &&
                                    <FormHelperText error={true}>Datum do mora biti večji od datuma od</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item style={{ marginTop: 6 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                Rezerviraj
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </Paper>

            <Scheduler schedulerData={viewModel}
                               prevClick={this.prevClick}
                               nextClick={this.nextClick}
                               onSelectDate={this.onSelectDate}
                               onViewChange={this.onViewChange}
                               eventItemClick={this.eventClicked}
                               viewEventClick={this.ops1}
                               //viewEventText="Ops 1"
                               //viewEvent2Text="Ops 2"
                               viewEvent2Click={this.ops2}
                               updateEventStart={this.updateEventStart}
                               updateEventEnd={this.updateEventEnd}
                               moveEvent={this.moveEvent}
                               newEvent={this.newEvent}
                               onSetAddMoreState={this.onSetAddMoreState}
                               toggleExpandFunc={this.toggleExpandFunc}
                    />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.state.snackbarOpen}
                autoHideDuration={2500}
                onClose={this.handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.snackbarMessage}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />

            {/* <Scheduler className={classes.root}
                               onScrollLeft={this.onScrollLeft}
                               onScrollRight={this.onScrollRight}
                               onScrollTop={this.onScrollTop}
                               onScrollBottom={this.onScrollBottom}
                               toggleExpandFunc={this.toggleExpandFunc}
                    /> */}
                    </MuiPickersUtilsProvider>
                
                <Dialog
                    open={this.state.dialogDeleteOpen}
                    onClose={this.handleDialogCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Ali ste prepričani, da želite izbrisati rezervacijo?"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Rezervacija bo odstranjena iz seznama.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDialogCancel} color="primary">
                        Prekliči
                    </Button>
                    <Button onClick={this.handleDialogOK} color="primary" autoFocus>
                        Potrdi
                    </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }


}

const mapStateToProps = state => ({
    user: state.user.data,
});


export default compose(
    withStyles(styles),
    DragDropContext(HTML5Backend),
    //,
    connect(mapStateToProps, {}),
  )(Rezervacija);