import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './scenes/components/PrivateRoute';
import asyncComponent from './scenes/components/hoc/asyncComponent';
import Rezervacija from './scenes/rezervacija/rezervacija.form';

const Home = from2('./scenes/home.form');
const Login = from2('./scenes/login.form');
const Register = from2('./scenes/register.form');
const Profile = from2('./scenes/profile.form');
const Users = from2('./scenes/users.form');
const Basic = from2('./scenes/basic');
const NedodeljenaOpravila = from2('./scenes/admin/nedodeljenaopravila.form');
const Opravila = from2('./scenes/tehnik/opravilatehnika.form');
const OpraviloEdit = from2('./scenes/admin/opraviloedit.form');
const OpraviloDodeli = from2('./scenes/admin/opravilododeli.form');
const MesecnoPorocilo = from2('./scenes/porocilo.form');
const OpravilaPregled = from2('./scenes/admin/opravilapregled.form');
const OpravilaAdmin = from2('./scenes/admin/opravilaadmin.form');

function from(path) {
  return asyncComponent(() => {
    return import(`${path}/index.js`);
  });
}

function from2(path) {
  return asyncComponent(() => {
    return import(`${path}`);
  });
}

export default () => (
  <Switch>
    <PrivateRoute exact path="/" component={Opravila} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <PrivateRoute path="/profile" component={Profile} />
    <PrivateRoute path="/users/all" component={Users} roles={['admin']}/>
    <PrivateRoute path="/rezervacija" component={Rezervacija} />
    <PrivateRoute path="/nedodeljeno" component={NedodeljenaOpravila} roles={['admin', 'administracija']} />
    <PrivateRoute path="/opravila" component={Opravila} />
    <PrivateRoute path="/opravilaedit" component={OpraviloEdit} roles={['admin', 'tehnik', 'administracija']} />
    {/* <PrivateRoute path="/opravilododeli" component={OpraviloDodeli} /> */}
    <PrivateRoute path="/porocilo" component={MesecnoPorocilo} />
    <PrivateRoute path="/opravilapregled" component={OpravilaPregled} roles={['admin']}/>
    <PrivateRoute path="/opravilaadmin" component={OpravilaAdmin} roles={['admin', 'administracija']} />

    <Redirect exact path="/users" to="/users/all" />
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);