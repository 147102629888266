import React, { useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { compose } from 'redux';
import NavigationList from './navigationList';
import getRoutes from '../routes';
import {
  Toolbar, Typography, IconButton, Menu, MenuItem, List, Avatar, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ListItemIcon, ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import LogoutIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person'
import { logout } from '../services/users/actions';
import AssignmentIcon from '@material-ui/icons/Assignment';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    //[theme.breakpoints.up('sm')]: {   //ne želimo pomika app - bar-a
    //  width: `calc(100% - ${drawerWidth}px)`,
    //  marginLeft: drawerWidth,
    //},
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerMobilePaper: {
    width: drawerWidth,
    marginTop: 65
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  flex: {
    flex: 1,  //s tem razširimo naslov in pomaknemo ostale ikone na desno
  },
}));

function ResponsiveDrawer(props) {
  const { window, user } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, handleToggleDialog] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    handleMenuClose();
    props.logout();
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const open = !!anchorEl;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit">
            <AssignmentIcon />
          </IconButton>
          <Typography variant="h5" className={classes.flex} noWrap>
            eZahtevek
          </Typography>

          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              src=""
            >
              <Avatar alt="Remy Sharp" src={user.profile} />
            </IconButton>
            {<Menu
              id="menu-appbar"
              onClose={handleMenuClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
            >
              <MenuItem onClick={handleMenuClose} component={Link} to="/profile">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profil" />
              </MenuItem>

              <MenuItem onClick={() => {
                handleMenuClose();
                handleToggleDialog(true);
              }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Odjava" />
              </MenuItem>
            </Menu>}
          </div>
        </Toolbar>

        <Dialog
          open={dialogOpen}
          onClose={() => { handleToggleDialog(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEnforceFocus
        >
          <DialogTitle id="alert-dialog-title">Ali ste prepričani, da se želite odjaviti?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sedaj se boste odjavili iz aplikacije eZahtevek.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { handleToggleDialog(false) }} color="primary">
              Prekliči
            </Button>
            <Button onClick={handleLogout} color="primary" autoFocus>
              Odjava
            </Button>
          </DialogActions>
        </Dialog>

      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerMobilePaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >

            <NavigationList />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {/* Vertikalni pomik menija */}
            <div style={{ height: 60 }} />
            <NavigationList />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          getRoutes()
        }

      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

//export default ResponsiveDrawer;

const mapStateToProps = state => ({
  user: state.user.data
});

//export default connect(mapStateToProps, {})(OpraviloEdit);

// const mapStateToProps = state => ({
//   user: state.user.data,
// })

export default compose(
  // withStyles(styles),
  connect(mapStateToProps, { logout }),
)(ResponsiveDrawer);
