import { createTheme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: blue
  },
  overrides: {
    //colorPrimary: red,
    MuiDrawer: {
        paper: {
          //background: '#18202c',
        },
    },
    MuiAppBar:{
      primary: red
    }
  },
});

export default theme;